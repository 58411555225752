<template>
  <div class="login-wrap">
    <div class="top-form">
      <img class="logo-img" :src="imgLogo">
      <el-button
            @click="navofficialsite"
            class="access-btn"
            size="small"
            type="primary"
            >访问无觅官网</el-button>
      <p class="split-line"></p>
    </div>
    <div class="login-form">
      <div class="title-form">
        <h2>绑定账号</h2>
      </div>
      <div class="bind-wrap">
        <el-form ref="loginForm" :rules="loginRules" :model="loginForm">
          <el-form-item prop="phonenumber">
            <el-input
              ref="phonenumber"
              clearable
              v-model="loginForm.phonenumber"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="verification">
            <el-input
              placeholder="请输入验证码"
              ref="verification"
              v-model="loginForm.verification"
            >
              <el-button
                class="verification-btn"
                type="primary"
                slot="append"
                :disabled="verifibtn"
                @click="getverificate"
                >{{ vervalue ? vervalue : "获取验证码" }}</el-button
              >
            </el-input>
          </el-form-item>
          <!--<el-form-item prop="password">
            <el-input
              placeholder="请输入密码"
              ref="password"
              clearable
              type="password"
              show-password
              v-model="loginForm.password"
            >
            </el-input>
          </el-form-item>-->
          <el-button
            :loading="loading"
            class="loginbtn"
            type="primary"
            @click.native.prevent="handleLogin"
            >绑定并登录</el-button
          >
        </el-form>
        <p class="accounttext">已有账号？输入账号信息立即绑定</p>
        <p class="accounttext">
          还没有账号？<span @click="navregister" class="register"
            >立即注册</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import navigation from "../../../utils/navigation";
import { isPhonenumber } from "../../../utils/validate";
import md5 from "js-md5"
import logo from "../../../assets/logo.png";
import { officalWebsite } from "@/utils/public";
import { Message } from 'element-ui';
export default {
  data() {
    const validatePhonenumber = (rule, value, callback) => {
      if (!isPhonenumber(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const validateVerifica = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        phonenumber: "",
        verification: "",
        password: "",
      },
      loginRules: {
        phonenumber: [
          { required: true, trigger: "blur", validator: validatePhonenumber },
        ],
        verification: [
          { required: true, trigger: "blur", validator: validateVerifica },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      vervalue: "",
      verifibtn: false,
      loading: false,
      imgLogo: logo,
    };
  },
  components: {},
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const data = {
            Type: 0,
            WechatUnionID: this.$route.params.WechatUnionID,
            PhoneNumber: this.loginForm.phonenumber,
            VerificationCode: this.loginForm.verification,
            Password: md5(this.loginForm.password),
          };
          this.$store
            .dispatch("user/userregister", { ...data })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$router.push({
                  path: "/",
                });
              }
              if (res.RetCode == 5) {
                this.$message.error("验证码错误");
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getverificate() {
      const valid = this.$refs.phonenumber.validateState;
      if (valid == "success") {
        this.verifibtn = true;
        var vartime = 60;
        var timer = setInterval(() => {
          vartime--;
          this.vervalue = `${vartime}s`;
          if (vartime == 0) {
            clearInterval(timer);
            this.verifibtn = false;
            this.vervalue = "";
          }
        }, 1000);
        var payload = {
          PhoneNumber: this.loginForm.phonenumber,
        };
        this.$store.dispatch("user/sendvertification", { ...payload });
        // TODO  发送成功后调用
        this.$message({
          showClose: true,
          message: '验证码发送成功',
          showClose: false,
          type: 'success'
        });
      } else {
        return;
      }
    },
    navregister() {
      navigation.navigateTo({
        url: "register",
      });
    },
    navofficialsite() {
      window.location.href = officalWebsite;
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.bind-wrap {
  width: 400px;
  margin: auto;
  margin-top: 35px;
  .el-form-item {
    margin-bottom: 30px;
  }
  ::v-deep .el-input__inner {
    border-radius: 5px;
  }
  ::v-deep .el-input-group__append {
    border: none;
  }
  .loginbtn {
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
    background: rgba(88, 151, 242, 1);
  }
  .verification-btn {
    width: 110px;
    border-radius: 5px;
    border: none !important;
    color: #fff;
    background: rgba(88, 151, 242, 1);
  }
  .accounttext {
    font-size: 14px;
    text-align: center;
    margin-top: 50px;
    .register {
      color: rgba(74, 99, 240, 0.93);
      cursor: pointer;
    }
  }
}
.login-wrap {
  width: 100%;
  height: 100%;
  background-size: cover;
  position:relative;

  .top-form {
    width: 100%;
    height: 60px;
    position: absolute;

    .logo-img {
      width: 60px;
      height: 60px;
      margin-left: 50px;
      vertical-align: middle;
		  text-align: center;
    }

    .access-btn {
      float: right;
      margin-top: 15px;
      margin-right: 50px;
      background:rgba(103, 149, 235, 1);
    }

    .split-line{
      height: 60px;
      //margin-top: 60px;
      border-top: 1px solid #E5E5E5;
    }
  }

  .left-page {
    width: 50%;
    height: 100%;
    background: rgba(70, 81, 96, 1);

    .logo-form {
      width: 100%;
      height: 100px;
      padding-top: 50px;
    }

    .logo-img {
      width: 100px;
      height: 80px;
      margin-right: 10px;
      vertical-align: middle;
		  text-align: center;
    }

    .logo-text {
      width: 100px;
      height: 80px;
      color: #fff;
      font-size: 30px;
      font-weight: 600;
      text-align: left;
      vertical-align: middle;
    }

    .intr-form {
      padding-top: 20px;
      padding-left: 10%;
      font-size: 20px;
      font-weight: 300;
      color: #fff;
    }

    .img-form {
      width: 90%;
      height: 50%;
      color: #000;
      padding-left: 5%;
      padding-top: 10%;
    }
  }

  .login-form {
    width: 600px;
    height: 550px;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 45px 20px;
    position: absolute;
    top: 100px;
    bottom: 0;
    left:50%;
    margin-left:-300px;
    box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
          rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
          rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
    
    .title-form {
      text-align: center;
      color: #000;
      margin-bottom: 45px;
    }
    .el-tabs {
      width: 400px;
      margin: auto;
    }
    ::v-deep .el-tabs__nav {
      transform: translateX(114px) !important;
    }
    ::v-deep .el-tabs__item {
      font-size: 16px;
    }
    ::v-deep .el-tabs__item.is-active {
      //   color: linear-gradient(
      //     90deg,
      //     rgba(74, 99, 240, 0.93) 0%,
      //     rgba(236, 179, 242, 1) 100%
      //   );
      color: rgba(74, 99, 240, 0.93);
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #4a63f0ed;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
  }
}
</style>